.listItem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    background-color: white;
    margin: .2rem 0;
}

.username {
    margin: .3rem;
    font-size: 2rem;
    font-weight: 300;
}

.email {
    margin: .3rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--primary);
}

.loggedin {
    margin: .3rem;
    font-size: 1.4rem;
    font-weight: 600;
}

