
 td {
    padding: 1rem .5rem;
}

td {
    background-color: white;
}



.desc{
    font-size: 1.4rem;
    color:grey;
    white-space: pre-line;
}

.times {
    color: #333;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
}

.trash {
    color: darkred;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s;
}

.trash:hover {
    color: red;
}

.lock {
    font-size: 1.4rem;
    color: grey;
}