.sessionsWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.sessionListContainer {
    margin: 0;
    padding: 0;
    position: relative;
}

.sessionsWrapperHeader{
    margin-top: -2rem;
    padding: 0 2rem; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 12rem;
}

.sessionsWrapperBody {
    margin: 0;
    padding: 2rem 0;
    background-color: white;
    min-height: 50rem;
    box-shadow: inset 0 .2rem .3rem rgba(0, 0, 0, .2);
}

.sessionsWrapperBody p {
    margin: 0 1rem;
    font-weight: 500;
    font-size: 1.2rem;
}

/*   question items  */

.question {
    margin: 1rem 1rem 1rem 0  ;
    margin-right: 30%;
    padding: 1rem 2rem;
    padding-bottom: 2.2rem;
    width: fit-content;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 0 1rem 1rem 0;
    color: white;
    overflow-y: scroll;
}

.response {
    margin: 1rem 0rem 1rem 1rem  ;
    padding: 1rem 2rem;
    margin-left: 30%;
    margin-top: -2.6rem;
    background-color: rgba(146, 21, 21, 01);
    border-radius: 1rem 0 0 1rem;
    color: white;
    overflow-y: scroll;
    border: .2rem solid #fff
}

.question h1 , .response h1 {
    margin: 0;
    font-weight: 500;
    font-size: 1.6rem;
}


/* new design */

