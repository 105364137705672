#qaToggleWrapper {
    display: flex;
}

.loadingDots div {
    background-color: var(--primary);
}

.contentContainer {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toggleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.labelContiner {
    flex-grow: 2;
    text-align: center;
}