.createSessionFormWrapper {
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
    display: flex;
}
.create-event-actions {
    display: flex;
    justify-content: flex-end;
}

.form {
    display: flex;
    align-items: center;
}

.createButton {
    margin: 0 .2rem;
    margin-bottom: 1rem;
    padding: 1.3rem 2rem;
    border-radius: .8rem;
}

.loadingDots div {
    background-color: var(--primary);
}