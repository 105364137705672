#actionsWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.button {
    margin: 1.5rem .5rem;
}

.approveNotice {
    margin: 0;
    padding: .8rem 1.5rem;
    font-size: 1.4rem;
    color: green;
    display: flex;
    justify-self: center;
    align-items: center;
    background-color: rgba(0, 255, 0, .1);
    border: 1px solid green;
    border-radius: 1rem;

}

.approveNotice span {
    font-size: 1.6rem;
    color: green;
    margin-right: 1rem;

}

.answeredNotice {
    margin: 0;
    padding: .8rem 1.5rem;
    font-size: 1.4rem;
    color: rgba(126, 43, 221, 1);;
    display: flex;
    justify-self: center;
    align-items: center;
    background-color: rgba(126, 43, 221, 0.1);
    border: 1px solid rgba(126, 43, 221, 1);
    border-radius: 1rem;
}

.answeredNotice span {
    font-size: 1.6rem;
    color: rgba(126, 43, 221, 1);;
    margin-right: 1rem;

}

.loadingWrapper{
    display: flex;
    width: 30%;
}
.loadingDots div {
    background-color: var(--primary);
}

.loadingWrapper span{
    font-size: 1.1rem;
}


.inputFocus {
    color: red;
}

.notificationWrapper {
    display: flex;
    width: 100%;
}
.notificationWrapper p {
    margin: .1rem 0;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--secondary)
}