.deleteLockWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    height: 4rem;
    width: 12.2rem;
    justify-content: center;
    align-items: center;
    
    border-radius: .5rem;
    background-color: lightgrey;
    cursor: pointer;
    transition: .3s;
}

.lockToggle {
    padding: 1rem;
    font-size: 2rem;
    color: grey;
    transition: .3s;

}

.deleteLockWrapper:hover {
    background-color: #bbbbbb;
}

.text {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    text-align: center;
    margin-right: 1rem;
    color: grey;
}

.innerContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dots {
    height: 10px;
}

.dots div {
    background-color: grey;
}