#poll-list{
    
}

.poll-list-container{
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 2rem;
}

.no-poll-list {
    margin: 0;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-poll-textbox{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
}

.no-poll-title{
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
    color: grey;
}
.no-poll-text {
    margin: .3rem;
    margin-bottom: 3rem;
    font-size: 1.4rem;
    color: var(--secondary);
    

}