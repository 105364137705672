#eventSettingsWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.groupWrapper {
    margin: 0 2%;
    padding:0;
    width: 90%;
}

.warningText {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-bottom: 0;
}