

.mainWrapper {
    margin: .5rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;

}

.mainTitle {
    margin: .3rem 0;
}

.logBody {
    overflow-y: scroll;
    height: 40rem;
    border: 1px solid lightgrey;
    border-radius: .5rem;
    background-color: white;
    

}
.actionsWrapper {
    position: relative;;
    display: flex;
    align-items: center;
}

.actionsWrapper label {
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: .3rem;
}

.inputWrapper {
    margin: .5rem;
    max-width: 21rem;
    flex-grow: 2;
}

.row {
    margin: .5rem 0;
    padding: 1rem;
    background-color: whitesmoke;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
}

.nologs {
    padding: 2rem;
}

.nologs h1 {
    color: var(--primary);
    margin: 0;
    font-size: 1.8rem;
}

.nologs p {
    color: #333;
    margin: 0;
    font-size: 1.4rem;
}