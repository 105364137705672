
.aContainer {
    display: flex;
    justify-content: flex-Start;
    align-items: flex-end;
    flex-direction: column;
}


.aBody {
    display: flex;
    width: fit-content;
    max-width: 70%;
    margin: 1rem 0rem 1rem 1rem  ;
    padding: 1rem 2rem;
    padding-bottom: 2.2rem;
    width: fit-content;
    background-color:rgba(146, 21, 21, 01);
    border-radius: 1rem 0 0 1rem;
    color: white;
    font-size: 1.4rem;
}

.aInfo {
    display: flex;
}

.aAuthor {
    display: flex;
}
.aTimestamp {
    display: flex;
}