.table {
    width: 100%;
    margin: 0 2rem;
    font-size: 1.6rem;
}

.header {
    background-color: var(--secondary);
    color:white;
    font-size: 1.1rem;
    
}

th, td {
    padding: 1rem .5rem;
}

td {
    background-color: white;
}



.desc{
    font-size: 1.4rem;
    color:grey;
    white-space: pre-line;
}

.times {
    color: #333;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
}

.trash {
    color: darkred;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s;
}

.trash:hover {
    color: red;
}