.trash {
    color: darkred;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s;
}

.trash:hover {
    color: red;
}

.message {
    flex-grow: 2;
}

.center {
    text-align: center;
}

.dots div {
    background-color: var(--primary);
}