#WOL {
    margin: 0;
    padding: 0;
    width: 100%;
}

.noUsers {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}