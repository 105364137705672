.poll-item__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem;
    padding: 0;
    min-width: 300px;
    max-width: 100vw;
    background-color: white;
    transition: .3s;
    border-radius: 1rem;
    box-shadow: 0 .3rem .8rem rgba(0, 0, 0, .2);
}

.poll-item__wrapper:hover {
    width: fit-content;
    background-color: #f9f9f9;
}

.poll-body-continer {
    padding:  0rem 2rem 2rem 2rem;
}

.poll-item-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    background: rgb(230,230,230);
    width: 100%;
    color: #333;
    border-radius: 0 0 .5rem .5rem ;
    border-top: .1rem solid rgb(210,210,210);
}

.poll-item-footer-toggle {
    display: flex;
    align-items: center;
    padding: 0 2rem;
}

.poll-item-footer-p {
    margin-left: 1rem;
    font-size: 1.4rem;
}
.pif-open {
    color: #0a8838;
}
.pif-closed {
    color: var(--primary);
}

.pim-show-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pim-show-menu-selector {
    padding: 0 1rem;
    height: 2rem;
    font-size: 3rem;
    color: #b6b6b6;
    width: fit-content;
    cursor: pointer;
}

.pim-show-menu-refresh {
    padding: 1rem;
    height: 2rem;
    font-size: 2rem;
    color: #b6b6b6;
    width: fit-content;
    cursor: pointer;
    transition: 1s;
}

.pim-show-menu-refresh:hover {
    transform: rotate(130deg);
}

.poll-item-menu {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: .3s;
    height: 0;
}

.pim-open {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    z-index: 500;
}

.pim-warning {
    padding: 0 4rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    text-align: center;
}

.pim-close {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    font-weight: 200;
    margin: 1rem 2rem;
    padding: 1rem ;
    border-radius: .5rem;
    border: 1px white solid;
    transition: .3s;
}

.pim-close:hover {
    background-color: gray;
}
.pim-warning-h2 {
    margin: 1rem 0 .2rem 0;
    font-size: 2rem;
    font-weight: 900;

}

.pim-warning-p {
    margin: .2rem 0;
    font-size: 1.4rem;
}

.pim-actions {
    display: flex;
    justify-content: space-around;
}

.poll-item-loading {
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.poll-item-loading-dots {
    height: 1.6rem;
}
.poll-item-loading-dots div {
    background-color: var(--primary);
}

.poll-item-loading-dots-h1, .poll-item-loading-dots-p {
    margin: 0;
    color: white;
}