.pie-chart-container {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.no-browser-data-notice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.no-browser-data-title {
    color: grey;
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
}
.no-browser-data-text {
    margin: 0;
    margin-top: 1rem;
    color: var(--primary);
    font-size: 1.4rem;
    max-width: 300px;
}
