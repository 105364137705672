.table {
    width: 100%;
    margin: 0 2rem;
    font-size: 1.6rem;
}

.header {
    background-color: var(--secondary);
    color:white;
    font-size: 1.1rem;
    
}

th, td {
    padding: 1rem .5rem;
}

td {
    background-color: white;
}


