.addAdgendWrapper {
    margin: 0 2rem;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.fieldWrapper {
    position: relative;
    margin: .5rem;
    display: flex;
    flex-direction: column;
}

.row, .actions{
    margin: 0rem;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    
}

.actions {
    justify-content: flex-end;
    align-items: stretch;
}

.input {
    
}
.title {
    flex-grow: 2;
}

.label {
    margin: 0;
    padding:.5rem  0;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary);
}

.required {
    font-size: 1rem;
    color: var(--secondary);
}

