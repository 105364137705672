.wrapper {
    margin: 0;
    padding:0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.titleConatiner {
    margin: .5rem 0;
    width: 30%;
    padding: 1rem;
    background-color: var(--primary);
    color: white;
    font-weight: 600;
    font-size: 1.8rem;
    border-radius: .5rem 0 0 .5rem  ;
}

.uriContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: .5rem 0;
    width: 65%;
    padding: 1rem;
    background-color: var(--secondary);
    color: white;
    font-weight: 600;
    font-size: 1.6rem;
    border-radius: 0  .5rem .5rem  0;
    cursor: pointer;
    transition: .3s;
    
}

.uriContainer:hover {
    transform: scale(1.1);
    box-shadow: 0 .8rem .6rem rgba(0, 0, 0, .6);
}

.uriContainer:active {
    transform: scale(1);
    box-shadow: none;

}

.notice {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 300;
    transition: .3s;
    
}

