.mainWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sessionItem {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
}

.title {
    background-color: white;
    margin: .5rem 0;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.8rem;
    color: var(--secondary);
}

.actions {
    display: flex;
}

.dots div {
    background-color: var(--primary);
}