.noticeWrapper {
    min-width: 100%;
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
}

.noticeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.noticeContainer h1 {
    margin: 0;
    font-size: 3rem;
    font-weight: 200;
}

.noticeContainer h2 {
    margin: 0;
    color: var(--primary);
}