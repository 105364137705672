
  .ext-cross:before, .checkbox__checker:before, .checkbox__cross:before, .checkbox__ok:before, .ext-cross:after, .checkbox__checker:after, .checkbox__cross:after, .checkbox__ok:after {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 2px;
    margin: 0 auto;
    top: 20px;
    left: 0;
    right: 0;
    background-color: #bf1e1e;
    border-radius: 5px;
    transition-duration: 0.3s;
  }
  .ext-cross:before, .checkbox__checker:before, .checkbox__cross:before, .checkbox__ok:before {
    transform: rotateZ(45deg);
  }
  .ext-cross:after, .checkbox__checker:after, .checkbox__cross:after, .checkbox__ok:after {
    transform: rotateZ(-45deg);
  }
  .ext-ok:before, .checkbox__toggle:checked + .checkbox__checker:before, .checkbox__ok:before, .ext-ok:after, .checkbox__toggle:checked + .checkbox__checker:after, .checkbox__ok:after {
    background-color: #0cb018;
  }
  .ext-ok:before, .checkbox__toggle:checked + .checkbox__checker:before, .checkbox__ok:before {
    width: 6px;
    top: 23px;
    left: -7px;
  }
  .ext-ok:after, .checkbox__toggle:checked + .checkbox__checker:after, .checkbox__ok:after {
    width: 12px;
    left: 5px;
  }
  .checkbox {
    width: 110px;
    margin: 0 auto 0px auto;
  }
  .checkbox__container {
    display: block;
    position: relative;
    height: 45px;
    cursor: pointer;
  }
  .checkbox__toggle {
    display: none;
  }
  .checkbox__toggle:checked + .checkbox__checker {
    left: calc(100% - 45px);
    transform: rotateZ(360deg);
  }
  .checkbox__checker, .checkbox__cross, .checkbox__ok {
    display: block;
    position: absolute;
    height: 45px;
    width: 45px;
    top: -1px;
    left: 0px;
    z-index: 1;
  }
  .checkbox__checker {
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    z-index: 2;
  }
  .checkbox__checker:before, .checkbox__checker:after {
    transition-duration: 0.3s;
  }
  .checkbox__cross:before, .checkbox__ok:before, .checkbox__cross:after, .checkbox__ok:after {
    background-color: #ddd;
  }
  .checkbox__ok {
    left: calc(100% - 45px);
  }
  .checkbox__txtLeft, .checkbox__txtRight {
    display: block;
    position: absolute;
    width: 45px;
    top: 15px;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 900;
    z-index: 1;
  }
  .checkbox__txtRight {
    right: 0px;
  }
  .checkbox__bg {
    position: absolute;
    top: 0;
    left: 0;
    fill: #aaa;
    width: 100%;
    height: 100%;
  }
  