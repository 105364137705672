#add-visitor {
    overflow-x: hidden;
    width: 500px;
    margin-left: 2rem;
}



.added-success {
    font-size: 1.5rem;
    font-weight: 600;
    color: green;
    margin: 0;
}