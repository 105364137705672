.video-loading {
    font-size: 1.4rem;
    font-weight: 300;
    color: var(--primary);
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    background-color: black;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .v-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
  }

  .v-overlay h1 {
      color: white;
  }