.visitor-item__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: .1rem 1rem;
    padding-left: 2rem;
    background-color: white;
    transition: .3s;
    cursor: pointer;
}

.visitor-item__wrapper:hover {
    background-color: #eee;
}


.user-info-box {
    margin: .5rem 0;
}
.visitor-item-name {
    margin:0;
    margin-bottom: .3rem;
    color: rgb(107, 107, 107);
    font-size: 1.2rem;
}
.visitor-item-email {
    margin:0;
    font-size: 1.6rem;
}

.user-org-box {
    margin: 0 1rem;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 2;
}

.visitor-org-name-label {
    margin:0;
    margin-bottom: .3rem;
    color: rgb(107, 107, 107);
    font-size: 1rem;
    font-weight: 400;
}

.visitor-org-name {
    margin:0;
    margin-bottom: .3rem;
    color: rgb(107, 107, 107);
    font-size: 1.4rem;
    font-weight: 900;
}

.vi-unregistered {
    margin-right:1rem ;
    color: darkred;
    font-size: 1.6rem;
}

.vi-registered {
    margin-right:1rem ;
    color: green;
    font-size: 1.6rem;
}

.last-logged-box {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    color: grey;
    font-size: .9rem;
    font-weight: 300;
}

.visitor-item-last-logged {
   margin: 0 1rem;
}

.loggedin-res {
    font-weight: 600;
    color: #333;
}

.vd-wrapper {
    margin: 0 1rem;
    margin-top: -.2rem;
    margin-bottom: 3rem;
    padding: 0 2rem;
    background: white;
    
}

.vd-login-count {
    text-align: right;
}

.vd-ticket_box {
    display: flex;
    align-items: center;
}


.vd-ticket-no {
    background: #e2e2e2;
    padding: .5rem 2rem;
    color: #333;
    font-weight: 600;
    font-size: 2.5rem;
    width: fit-content;
}