.bar-chart-container {
    position: relative;
    height: 300px;
    width: 300px;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;


}

.no-data-notice {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.no-data-title {
    color: grey;
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
}
.no-data-text {
    margin: 0;
    margin-top: 1rem;
    color: var(--primary);
    font-size: 1.4rem;
    max-width: 300px;
}