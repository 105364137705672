.table {
    width: 100%;
    margin:0 ;
    font-size: 1.6rem;
}

.header {
    background-color: var(--secondary);
    color:white;
    font-size: 1.1rem;
    
}

th, td {
    padding: 1rem .5rem;
}

td {
    background-color: white;
}



.desc{
    font-size: 1.4rem;
    color:grey;
    white-space: pre-line;
}

.times {
    color: #333;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
}

.trash {
    color: darkred;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s;
}

.trash:hover {
    color: red;
}

.mainAction {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.warningWrapper {
    margin: 0;
    margin-top: .5rem;
    padding:0 ;
    display: flex;
    justify-content: center;
    align-items: center;
} 

.warningHead {
    padding: 1rem 2rem;
    background-color: darkred;
    color: white;
    font-weight: 900;
    font-size: 1.4rem;
    border-radius:  .5rem 0 0 .5rem  ;
}
.warningBody {
    padding: 1rem 2rem;
    background-color: black;
    color: white;
    font-weight: 600;
    font-size: 1.4rem;
    flex-grow: 2;
    border-radius: 0 .5rem .5rem 0 ;
}
