.pageWapper {
    margin-left: 150px;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
}

.pc-stats {
    display: flex;
    flex-wrap: wrap;
    max-height: 95vh;
    overflow-y: scroll;
}

.pageContent {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-grow: 2;
}
.stat-container {
    margin: 2rem;
    width: 100%;
    min-width: 30%;
    padding: 3rem;
    background-color: white;
}

.ccContainer {
    width: 100%;
}